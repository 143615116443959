import * as React from "react"
import { PropsWithChildren } from "react";

type Props = {
    speed?: 'slow' | 'normal' | 'fast'
}

const FadeInSection = (props: PropsWithChildren<Props>) => {
    const [isVisible, setVisible] = React.useState(true)
    const domRef = React.useRef<HTMLDivElement|null>(null)

    React.useEffect(() => {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => setVisible(entry.isIntersecting))
      })

      if (domRef.current) {
          observer.observe(domRef.current)
      }

      return () => {
          if (domRef.current) {
              observer.unobserve(domRef.current)
          }
      }
    }, [])

    return (
      <div
        className={`fade-in-section-${props.speed} ${isVisible ? 'is-visible' : ''}`}
        ref={domRef}
      >
        {props.children}
      </div>
    )
}

FadeInSection.defaultProps = {
    speed: 'normal'
}

export default FadeInSection
