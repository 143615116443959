import * as React from "react"
import type { HeadFC } from "gatsby"
import { graphql } from "gatsby"

import Headers from '../components/Headers'

import Layout from "../components/Layout"

import showcaseImage from '../images/screens/today-screen.png'
import screenQuest from '../images/screens/quest.png'
import screenChat from '../images/screens/chat.png'
import screenLibrary from '../images/screens/library.png'

import FadeInSection from "../components/FadeInSection"

//import Lottie from "lottie-react"
//import animationDataSmiley from '../animations/smiley.json'
//import animationDataHeroDe from '../animations/hero_de.json'
//import animationDataHeroEn from '../animations/hero_en.json'

import BadgeGooglePlay from '../images/badges/google-play-badge-de.png'
import BadgeAppleStore from '../images/badges/appstore-de.png'
import showcaseImage2 from '../images/screens/onboarding.png';
import TeaserVideoLandscape from "../video/teaser.mp4"
import TeaserVideoPortrait from "../video/teaser-portrait.mp4"

import iapLogoDe from "../images/science/iap_de.png"
import iapLogoEn from "../images/science/iap_en.svg"

import girlYAde from '../images/girl ya de.png'
import girlYAen from '../images/girl ya en.png'

import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PlatformIcons from "../images/ios_android_grey.png"

export const query = graphql`
  query HomePageQuery($lang: String!) {
    markdownRemark(
      fields: {lang: {eq: $lang}}
      fileAbsolutePath: {regex: "/home.md/"}
    ) {
      frontmatter {
        title
        description
        emotionalValue
        ourOffering
        ctaButton
        result1
        result2
        result3
        subtitle
        feature1Title
        feature2Title
        feature3Title
        feature1Text
        feature2Text
        feature3Text
      }
      html
    }
  }
  `


const Download = () => <a href="/get-ephoria">
<div style={{flex:1, flexDirection: 'row', gap: 30, marginTop: 40}}>
    <img src={BadgeAppleStore} height={60} alt="Appstore" style={{marginRight: 50}}/>
    <img src={BadgeGooglePlay} height={60} alt="Google Play" style={{marginTop: 10}}/>
</div>
</a>



  const IndexPage = ({data, pageContext}: any) => {

    const sourcesLabel = pageContext.lang == 'en' ? 'Sources' : 'Quellen'
    //const [smileyIsVisible, setSmileyIsVisible] = React.useState(false)

    //const observedContainer = React.useRef<HTMLDivElement|null>(null)
    //const lottieRef = React.useRef<LottieRefCurrentProps>(null);
    // get screen width
    const [teaser, setTeaser] = React.useState()
    React.useEffect(() => {
        if(window.innerWidth > 768) setTeaser(TeaserVideoLandscape)
        else setTeaser(TeaserVideoPortrait)
    }, [])

  data = data.markdownRemark


  /*React.useEffect(() => {

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => setSmileyIsVisible(entry.isIntersecting))
    })

    if (observedContainer.current) {
        observer.observe(observedContainer.current)
    }

    //return () => anim.destroy(); // optional clean up for unmounting
  }, []);
*/


  /*React.useEffect(() => {
    //console.log("fx", smileyIsVisible)
    if(!lottieRef.current) return
    if (smileyIsVisible) {
      lottieRef.current.setSpeed(0.6)
      lottieRef.current.play()
    }
    else lottieRef.current.goToAndStop(0)

  }, [smileyIsVisible])
*/


  return (
    <Layout lang={pageContext.lang}>
        <div className="banner home">
            <div>
                <h1>{data.frontmatter.emotionalValue}</h1>
                <p>{ data.frontmatter.ourOffering }</p>
                <ul>
                    <li>{data.frontmatter.result1}</li>
                    <li>{data.frontmatter.result2}</li>
                    <li>{data.frontmatter.result3}</li>
                </ul>
                <Download />
            </div>
            <img src={showcaseImage} alt="ephoria screenshot" className="featureImage"/>
            {/* <Lottie animationData={pageContext.lang == 'de' ? animationDataHeroDe : animationDataHeroEn}
                  className="heroLottie"
                  autoplay={true}
                  loop={false}
            /> */}
        </div>

        <div className="subbanner">
            <div className="container2Cols">

            <img src={pageContext.lang == 'de' ? girlYAde : girlYAen} alt="ephoria quests" className="featureImage2"/>
            <div style={{padding: 20}}>
                <h1>{data.frontmatter.subtitle}</h1>
                <div dangerouslySetInnerHTML={{ __html: data.html }} />
                <div className="centerMobile">
                    <FadeInSection speed="slow">
                        <img src={pageContext.lang == 'de' ? iapLogoDe : iapLogoEn} alt="ZHAW IAP Logo" width={280} style={{marginTop: 60}}/>
                    </FadeInSection>
                </div>
            </div>
            </div>

            { teaser &&
            <div className="video">
                <video controls>
                    <source src={teaser + "#t=0.001"} type="video/mp4"/>
                </video>
            </div>
            }

            <div className="testimonials">
                { pageContext.lang === 'de' &&
                <h1>78% der regelmässigen Nutzer reduzieren Symptome von Depression innert zwei Wochen.<sup>1</sup></h1>
                }
                { pageContext.lang === 'en' &&
                <h1>78% of regular users reduce symptoms of depression within two weeks.<sup>1</sup></h1>
                }
{/*}
                <Lottie animationData={animationDataSmiley}
                    lottieRef={lottieRef}
                    style={{width: 150, height: 150, margin: '0 auto', marginBottom: 30}}
                    autoplay={false}
                    loop={false}
                />
            */}
                <div className="testimonialContainer"
                //ref={observedContainer}
                >
                    { pageContext.lang === 'de' &&
                    <>
                        <div className="testimonial">
                        "Ich bin von der App begeistert!"
                        </div>

                        <div className="testimonial">
                        "Die Antworten im Chat kommen sofort und ich finde die App gibt wirklich eine gute Hilfestellung."
                        </div>

                        <div className="testimonial">
                        "Ich nutze ephoria fast täglich"
                        </div>
                    </>
                    }
                    { pageContext.lang === 'en' &&
                    <>
                        <div className="testimonial">
                            "I am thrilled with the app!"
                        </div>

                        <div className="testimonial">
                            "The responses in the chat are instantaneous and the app is really helpful."
                        </div>

                        <div className="testimonial">
                            "I use ephoria almost every day"
                        </div>
                    </>
                    }



                </div>

                <div style={{marginTop: 80, marginBottom: 30}}>
                    { pageContext.lang === 'de' && <>
                    5 Sterne im App Store and Play Store!
                    </>
                    }
                    { pageContext.lang === 'en' && <>
                    5 Star Rating on App Store and Play Store!
                    </>
                    }
                </div>

                <div>
                    <FontAwesomeIcon icon={faStar} size="2x" color="#ffc919"/>
                    <FontAwesomeIcon icon={faStar} size="2x" color="#ffc919"/>
                    <FontAwesomeIcon icon={faStar} size="2x" color="#ffc919"/>
                    <FontAwesomeIcon icon={faStar} size="2x" color="#ffc919"/>
                    <FontAwesomeIcon icon={faStar} size="2x" color="#ffc919"/>
                </div>
                <img src={PlatformIcons} height={50} style={{marginTop: 20}} alt="ephoria app for ios and android"/>


            </div>



            <div className="featureContainer">
                <FadeInSection speed="fast">
                    <div className="feature">
                        <img src={screenQuest} alt="Quest" style={{maxHeight: 500, marginBottom: 30}}/>
                        <h2>{data.frontmatter.feature1Title}</h2>
                        <p>{data.frontmatter.feature1Text}</p>
                    </div>
                </FadeInSection>

                <FadeInSection>
                    <div className="feature">
                        <img src={screenChat} alt="Chat" style={{maxHeight: 500, marginBottom: 30}}/>
                        <h2>{data.frontmatter.feature2Title}</h2>
                        <p>{data.frontmatter.feature2Text}</p>
                    </div>
                </FadeInSection>

                <FadeInSection speed="slow">
                    <div className="feature">
                        <img src={screenLibrary} alt="Library" style={{maxHeight: 500, marginBottom: 30}}/>
                        <h2>{data.frontmatter.feature3Title}</h2>
                        <p>{data.frontmatter.feature3Text}</p>
                    </div>
                </FadeInSection>
            </div>
            <FadeInSection>
                <img src={showcaseImage2} alt="ephoria screenshot chat" className="featureImage"/>
            </FadeInSection>



            <div style={{marginTop: 60}}>
                <h1 style={{textAlign: 'center'}}>{data.frontmatter.ctaButton}</h1>
            </div>
            <Download />
        </div>

        <div className="sources">
            { /* APA citation style preferred */}
                <p>{sourcesLabel}:</p>
                <ul>
                    <li>
                    [1] PHQ-9 focus group, April 2024
                    </li>
                </ul>
            </div>
    </Layout>
  )
}

export default IndexPage

// add headers
export const Head: HeadFC = ({ location, params, data, pageContext }: any ) => {
    return <Headers
    lang={pageContext.lang}
    title={data.markdownRemark.frontmatter.title}
    description={data.markdownRemark.frontmatter.description}
    location={location}
    />
}
